@use 'styles/includes' as *;

.SocialProof {
    $root: &;
    background-color: $black;

    &__Container {
        @extend %container;
        padding-top: 4rem;
        padding-bottom: 4rem;

        @include media(l) {
            padding-top: 8rem;
            padding-bottom: 8rem;
        }
    }

    &__Title {
        margin: 0 0 40px 0;
        font-family: $font-family-bold;
        font-size: 2.4rem;
        line-height: 120%;
        white-space: pre-line;
        color: $white;

        @include media(l) {
            font-size: 3rem;
        }
    }

    &__Grid {
        display: grid;
        align-items: center;
        gap: 20px;

        @include media(l) {
            grid-template-columns: repeat(3, 1fr);
            gap: 40px;
        }
    }

    &__Button {
        font-family: $font-family-bold;
        font-weight: $font-weight-bold;
        font-size: 1.6rem;
        line-height: 100%;
        text-align: center;
        color: $white;
        border: 1px solid $grey-70;
        display: inline-flex;
        align-items: stretch;
        justify-content: flex-start;

        @include media-max(l) {
            margin: 40px 0 0;
            width: 100%;
            flex-wrap: wrap;
        }

        @include media(l) {
            font-size: 2rem;
        }
    }

    &__ButtonTitle {
        padding: 2rem 3.2rem;
        flex-grow: 1;

        @include media-max(l) {
            width: 100%;
            border-bottom: 1px solid $grey-70;
        }

        @include media(l) {
            border-right: 1px solid $grey-70;
        }
    }

    &__Icon {
        padding: 1.4rem 2rem;
        display: flex;

        @include media-max(l) {
            width: 50%;
            align-items: center;
            justify-content: center;
        }

        span {
            width: 16px;
            height: 16px;
        }

        &:last-of-type {
            border-left: 1px solid $grey-70;
        }
    }

    &__Rating {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;

        @include media(l) {
            justify-content: flex-start;
        }

        &:nth-child(1),
        &:nth-child(2) {
            @include media-max(l) {
                padding-top: 20px;
                border-top: 1px solid $grey-90;
            }

            @include media(l) {
                border-right: 1px solid $grey-90;
            }
        }

        svg {
            height: 20px;
        }
    }

    &__Number {
        font-family: $font-family-bold;
        font-size: 6rem;
        font-weight: $font-weight-bold;
        line-height: normal;
        color: $white;
    }

    &__OutOf {
        font-family: $font-family-bold;
        font-size: 1.8rem;
        font-weight: $font-weight-bold;
        line-height: normal;
        color: $grey-50;
    }

    &__Link {
        font-family: $font-family-bold;
        font-size: 1.8rem;
        font-weight: $font-weight-bold;
        text-decoration-line: underline;
        text-underline-position: from-font;
        color: $white;

        @media (hover: hover) {
            &:hover {
                text-decoration: none;
            }
        }
    }
}
